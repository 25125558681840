<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <div class="alert alert-warning" role="alert">
          <b>Atenção: </b> o tamanho ideal para o banner é 672x310 px, máximo de
          1MB.
        </div>
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-8">
              <base-input label="Nome do banner" v-model="form.name"></base-input>
            </div>

            <div class="col-4">
              <base-input label="Ordem de exebição" v-model="form.odr" @keypress="$helpers.isNumber" />
            </div>

            <div class="col-12">
              <div class="form-group">
                <base-checkbox v-model="form.all_cities">
                  Todas as cidades
                </base-checkbox>
              </div>
            </div>

            <div class="col-4">
              <label>Estado</label>
              <select @change="getCities()" v-model="tempCity.province" class="form-control">
                <option v-for="(province, index) in provinces" :key="province.id" :value="index">{{ province.name }}</option>
              </select>
            </div>

            <div class="col-4">
              <label>Cidade</label>
              <select v-model="tempCity.city" class="form-control">
                <option v-for="(city, index) in cities" :key="city.id" :value="index">{{ city.name }}</option>
              </select>
            </div>

            <div class="col-md-4 mt-3 pt-1">
              <div class="row">
                <div class="col-md-6">
                  <base-button type="primary" class="btn-block" @click="addCity">Adicionar</base-button>
                </div>
                <div class="col-md-6">
                  <base-button type="danger" class="btn-block" @click="tempCity = {}">Cancelar</base-button>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="row" v-if="form.cities.length && !this.form.all_cities">
                <div class="col-12">
                  <h4>Cidades Selecionadas</h4>

                  <div class="cities-content">
                    <div class="city-item" v-for="(city, index) in form.cities" :key="index">
                      {{ city.city_name }}/{{ city.province_initials }}
                      <i class="fa fa-trash" @click="removeCity(index)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <label>Imagem do banner </label>
              <div class="upload-container">
                <div class="upload-preview" :style="'background: url(' + form.image + ')'" v-if="form.image !== '' && form.image !== null">
                  <div class="remove-file" @click="form.image = ''">
                    <p><i class="fa fa-times"></i></p>
                    <p>Remover</p>
                  </div>
                </div>
                <div class="add-file">
                  <label for="image" v-if="!(form.image !== '' && form.image !== null)">
                    Carregar
                    <span class="tim-icons icon-upload"></span>
                  </label>
                  <label for="image" v-if="form.image !== '' && form.image !== null">
                    Alterar
                    <span class="tim-icons icon-pencil"></span>
                  </label>
                  <input @change.prevent="addImage" type="file" id="image" accept="image/png, image/jpeg, image/jpg" />
                </div>
              </div>
            </div>

            <div class="col-6">
              <label class="mr-3 mb-0">Status</label>

              <el-radio-group v-model="form.status">
                <el-radio class="mb-0" :label="1">Ativo</el-radio>
                <el-radio class="mb-0" :label="0">Inativo</el-radio>
              </el-radio-group>
            </div>

            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button @click.prevent.stop="goBack()" type="danger">Cancelar</base-button>
              <base-button class="ml-3" native-type="submit" type="success">Salvar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import { BaseCheckbox } from '@/components/index'
import swal from 'sweetalert2'
export default {
  data: () => ({
    form: {
      status: 0,
      image: '',
      cities: [],
      name: '',
      all_cities: true
    },
    categories: [],
    provinces: [],
    cities: [],
    tempCity: {}
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`banners/${id}`)).data.data[0]
      }
    },
    async getProvinces () {
      this.provinces = (await this.$http.get('provinces')).data
    },
    async getCities () {
      const id = this.provinces[this.tempCity.province].id
      this.cities = (await this.$http.get(`provinces/cities/${id}`)).data
    },
    addCity () {
      if (!this.tempCity.province || !this.tempCity.province) {
        return
      }

      this.form.all_cities = false
      const obj = {}

      obj.province_id = this.provinces[this.tempCity.province].id
      obj.province_initials = this.provinces[this.tempCity.province].initials
      obj.city_id = this.cities[this.tempCity.city].id
      obj.city_name = this.cities[this.tempCity.city].name

      this.form.cities.push(obj)
      this.tempCity = {}
    },
    async removeCity (index) {
      this.form.cities.splice(index, 1)
      // eslint-disable-next-line no-unused-expressions
      this.form.cities.length === 0 ? (this.form.all_cities = true) : null
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`banners/${id}`, this.form)).data.data.status
          }
          if (!id) status = (await this.$http.post('banners', this.form)).data.data.status
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          await swal.fire({
            title: 'Salvo!',
            text: `"${this.form.name}" salvo com sucesso.`,
            showConfirmButton: false,
            timer: 1000
          })
          this.goBack()
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    addImage (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        vue.form.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    validate () {
      const form = this.form
      if (!form.image || form.image === '') return false
      return true
    },
    selectedValue (newValue) {
      this.form.status = newValue
    }
  },
  mounted () {
    this.getData()
    this.getProvinces()
  },
  components: {
    BaseCheckbox
  }
}
</script>

<style>
  .cities-content {
    display: flex;
    flex-direction: row;
  }

  .city-item {
    background: #f3f3f3;
    padding: 0.5rem 0.75rem;
    border-radius: 2rem;
    margin-right: 0.5rem;
  }

  .city-item i {
    margin-left: 0.4rem;
    cursor: pointer;
  }
</style>
